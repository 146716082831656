import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';

const Docs = lazy(() => import('./pages'));

const App = () => {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div />}>
          <Docs />
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
